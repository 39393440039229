var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('router-link', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3",
    class: _vm.$colors.primary,
    attrs: {
      "to": "/categories"
    }
  }, [_c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v("chevron_left")])]), !_vm.formData._id ? _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" New Categorie ")]) : _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" Edit Categorie ")])], 1)]), _c('div', {}, [_c('div', {
    staticClass: "bg-white rounded-lg p-6"
  }, [_c('div', {
    staticClass: "space-y-4 px-10"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "name"
    }
  }, [_vm._v("Name "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.name,
      expression: "formData.name"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "name",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "description"
    }
  }, [_vm._v("Description "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.description,
      expression: "formData.description"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "description",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.description
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "description", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "mt-6 pt-3 flex justify-center"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Save ")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }